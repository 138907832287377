import { Grid, GridItem, Flex, Button, Input, Text, Stack } from '@chakra-ui/react'
import React, { useEffect, useMemo } from 'react'
import Header from '../../../app/components/Header'
import DateRangeInputs from '../../../app/components/DateRangePicker/DateRangeInputs'
import { useForm } from 'react-hook-form'
import { useGetAllLocationsQuery } from '../api/reportsApi'
import { IFormFields } from '../api/types'
import { useGetReportAsPDFMutation } from '../../../app/services/api/api'
import SingleSelect from '../components/SingleSelect'
import MultiSelect from '../components/MultiSelect'
import {
  useGetDeviceTypesQuery,
  useGetUserDevicesQuery,
  useGetDevicesCsvReportMutation,
  useGetEnergyTypesQuery,
} from '../../locations/api/locationsApi'
import { useGetDeviceGroupListQuery } from '../../deviceConfiguration/api/deviceGroupConfigurationApi'
import { TrendReportFilters } from '../forms/TrendReportFilters'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import { AiOutlineFilePdf, AiOutlineFileExcel } from 'react-icons/ai'

enum DIRECTION {
  COL = 'column',
  ROW = 'row',
}

enum ReportType {
  Pdf = 'PDF',
  Csv = 'Csv',
}

const REPORT_TYPES = [{ id: 1, name: 'Trend report' }]

const GenerateReport: React.FC = () => {
  const { data: locations } = useGetAllLocationsQuery()
  const { data: devicesGroups } = useGetDeviceGroupListQuery()

  const [getReportAsPDF, { isLoading: isPdfLoading }] = useGetReportAsPDFMutation()
  const [getDevicesCsvReport, { isLoading: isCsvLoading }] =
    useGetDevicesCsvReportMutation()

  const {
    register,
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isValid: formIsValid },
  } = useForm<IFormFields>()

  useEffect(() => {
    setValue(
      'selectedLocations',
      locations?.results.map((location: any) => location.id.toString()),
    )
  }, [locations, setValue])

  const setSelectedLocations = (selectedLocations: string[]) => {
    setValue('selectedLocations', selectedLocations)
  }
  const setSelectedDeviceTypes = (selectedDeviceTypes: string[]) => {
    setValue('selectedDeviceTypes', selectedDeviceTypes)
  }
  const setSelectedDevicesGroups = (selectedDevicesGroups: string[]) => {
    setValue('selectedDevicesGroups', selectedDevicesGroups)
  }
  const setSelectedEnergyType = (selectedEnergyType: number) => {
    setValue('selectedEnergyType', selectedEnergyType)
  }
  const setSelectedReportType = (selectedReportType: number) => {
    setValue('selectedReportType', selectedReportType)
  }
  const setSelectedTotalDetailed = (selectedTotalDetailed: number) => {
    setValue('selectedTotalDetailed', selectedTotalDetailed)
  }
  const setSelectedDevices = (selectedDevices: string[]) => {
    setValue('selectedDevices', selectedDevices)
  }
  const setSelectedMeasurementTypes = (selectedMeasurementTypes: string[]) => {
    setValue('selectedMeasurementTypes', selectedMeasurementTypes)
  }

  const startDate = watch('startDate') || null
  const endDate = watch('endDate') || null
  const selectedEnergyType = watch('selectedEnergyType') || null
  const selectedReportType = watch('selectedReportType') || null
  const selectedTotalDetailed = watch('selectedTotalDetailed') || null
  const selectedLocations = watch('selectedLocations') || []
  const selectedDeviceTypes = watch('selectedDeviceTypes') || []
  const selectedDevicesGroups = watch('selectedDevicesGroups') || []
  const selectedDevices = watch('selectedDevices') || []
  const selectedMeasurementTypes = watch('selectedMeasurementTypes') || []

  const generateCsvData = (data) => {
    const headers = Object.keys(data[0])
    const csvRows = [
      headers.join(','),
      ...data.map((row) =>
        headers.map((header) => JSON.stringify(row[header] || '')).join(','),
      ),
    ]

    return csvRows.join('\n')
  }

  async function onSubmit(formData: IFormFields, saveAs: ReportType) {
    const toastId = toast.info('Generating report...', {
      style: { padding: '10px 20px' },
      autoClose: false,
      isLoading: true,
      progress: undefined,
    })

    try {
      if (saveAs === ReportType.Pdf) {
        await getReportAsPDF({
          energy_type: formData.selectedEnergyType || null,
          location_ids: formData.selectedLocations,
          device_type: formData.selectedDeviceTypes || [],
          device_groups: formData.selectedDevicesGroups,
          from_date: formData.startDate.toLocaleDateString('en-US'),
          to_date: formData.endDate.toLocaleDateString('en-US'),
          device_ids: formData.selectedDevices,
          report_name: formData.reportName,
        }).unwrap()

        toast.update(toastId, {
          render: 'PDF report generated successfully!',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        })
      } else if (saveAs === ReportType.Csv) {
        const res = await getDevicesCsvReport({
          from_date: formData.startDate.toLocaleDateString('en-US'),
          to_date: formData.endDate.toLocaleDateString('en-US'),
          device_ids: formData.selectedDevices,
          report_name: formData.reportName,
        }).unwrap()

        const csvData = generateCsvData(res)
        const csvBlob = new Blob([csvData], { type: 'text/csv' })
        const csvUrl = URL.createObjectURL(csvBlob)

        const a = document.createElement('a')
        a.href = csvUrl
        a.download = `${formData.reportName}.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)

        toast.update(toastId, {
          render: 'CSV report generated successfully!',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        })
      }
    } catch (_) {
      toast.update(toastId, {
        render: `Failed to generate report`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      })
    }
  }

  const handleReset = () => {
    reset({ reportName: '' })
    setValue(
      'selectedLocations',
      locations?.results.map((location: any) => location.id.toString()),
    )
    setValue('startDate', null)
    setValue('endDate', null)
    setValue('selectedDevicesGroups', [])
    setValue('selectedDeviceTypes', [])
    setValue('selectedEnergyType', null)
    setValue('selectedReportType', null)
    setValue('selectedDevices', [])
    setValue('selectedTotalDetailed', 1)
    setValue('selectedMeasurementTypes', [])
    setValue('selectedDevices', [])
    setValue('selectedTotalDetailed', null)
  }

  const { data: userDevices } = useGetUserDevicesQuery({
    locationIds: selectedLocations,
    deviceTypeIds: selectedDeviceTypes,
    deviceGroups: selectedDevicesGroups,
    measurementTypeIds: selectedMeasurementTypes,
    energyType: selectedEnergyType,
  })

  const { data: deviceTypes } = useGetDeviceTypesQuery({
    groupIds: selectedDevicesGroups,
  })
  const { data: energyTypes } = useGetEnergyTypesQuery({
    deviceIds: selectedDevices,
  })

  const locationSelect = locations?.results.map(
    ({ title, id }: { title: string; id: string }) => ({
      id,
      name: title,
    }),
  )

  const deviceTypeSelect = useMemo(
    () =>
      deviceTypes?.results.map(({ id, title }) => ({
        id,
        name: title,
      })),
    [deviceTypes],
  )

  const devicesGroupsSelect = useMemo(
    () =>
      devicesGroups?.map(({ id, name }) => ({
        id,
        name,
      })),
    [devicesGroups],
  )

  const energyTypesSelect = useMemo(
    () =>
      energyTypes?.results.map(({ id, name }) => ({
        id,
        name,
      })),
    [energyTypes],
  )

  const reportTypesSelect = REPORT_TYPES.map(({ id, name }) => ({
    id,
    name,
  }))

  const userDevicesSelect = useMemo(
    () =>
      userDevices?.results.map(({ id, title }) => ({
        id,
        name: title,
      })),
    [userDevices],
  )

  return (
    <>
      <Header title="Reports" />

      <Grid templateColumns={`repeat(${'2'}, 1fr)`}>
        <GridItem h="100%" w="95%">
          <Flex display="flex" direction={DIRECTION.COL} gap={5} as="form">
            <Flex display="flex" direction={DIRECTION.ROW} gap={5}>
              <Button
                alignSelf="flex-start"
                colorScheme="button.primary"
                size="sm"
                onClick={handleReset}
              >
                Reset filters
              </Button>
            </Flex>
            {/* @ts-ignore */}
            <DateRangeInputs
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              selectedStartName="startDate"
              selectedEndName="endDate"
              startDate={startDate}
              endDate={endDate}
              highlightOnError
            />
            <MultiSelect
              items={locationSelect}
              isDisabled={!startDate && !endDate}
              setSelectedOptions={setSelectedLocations}
              selectedOptions={selectedLocations}
              setValue={setValue}
              required={selectedLocations.length === 0}
              invalidateFields={['selectedDevices']}
              header={`Select location/s${
                selectedLocations.length > 0 ? ` (${selectedLocations.length})` : ''
              }`}
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            <MultiSelect
              items={devicesGroupsSelect}
              isDisabled={!startDate && !endDate}
              setSelectedOptions={setSelectedDevicesGroups}
              selectedOptions={selectedDevicesGroups}
              setValue={setValue}
              invalidateFields={[
                'selectedDeviceTypes',
                'selectedEnergyType',
                'selectedDevices',
              ]}
              header={`Select device group/s${
                selectedDevicesGroups.length > 0
                  ? ` (${selectedDevicesGroups.length})`
                  : ''
              }`}
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            <MultiSelect
              items={deviceTypeSelect}
              isDisabled={!startDate && !endDate}
              setSelectedOptions={setSelectedDeviceTypes}
              selectedOptions={selectedDeviceTypes}
              setValue={setValue}
              invalidateFields={['selectedMeasurementTypes', 'selectedDevices']}
              header={`Select device type/s${
                selectedDeviceTypes.length > 0 ? ` (${selectedDeviceTypes.length})` : ''
              }`}
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            <SingleSelect
              key="energy-type"
              items={energyTypesSelect}
              isDisabled={!startDate && !endDate}
              {...register('selectedEnergyType')}
              setSelectedOption={setSelectedEnergyType}
              selectedOption={selectedEnergyType}
              setValue={setValue}
              invalidateFields={['selectedDevices']}
              header="Select energy type"
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            <SingleSelect
              key="report-type"
              items={reportTypesSelect}
              isDisabled={!startDate && !endDate}
              {...register('selectedReportType')}
              setSelectedOption={setSelectedReportType}
              selectedOption={selectedReportType}
              required={selectedReportType === null}
              header="Select report type"
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            {selectedReportType === 1 ? (
              <TrendReportFilters
                selectedEnergyType={selectedEnergyType}
                selectedTotalDetailed={selectedTotalDetailed}
                setSelectedTotalDetailed={setSelectedTotalDetailed}
                selectedMeasurementTypes={selectedMeasurementTypes}
                setSelectedMeasurementTypes={setSelectedMeasurementTypes}
                selectedDeviceTypes={selectedDeviceTypes}
                register={register}
                setValue={setValue}
              />
            ) : null}
            <MultiSelect
              items={userDevicesSelect}
              isDisabled={!startDate && !endDate}
              setSelectedOptions={setSelectedDevices}
              selectedOptions={selectedDevices}
              required={selectedDevices.length === 0}
              header={`Select device/s${
                selectedDevices.length > 0 ? ` (${selectedDevices.length})` : ''
              }`}
              tooltip={{
                label: 'Please select period',
                hasArrow: true,
                placement: 'top',
              }}
            />
            <Input
              w="full"
              isDisabled={!startDate && !endDate}
              bg="white"
              border="1px"
              height="48px"
              borderColor={watch('reportName') ? 'border.strong' : 'red.500'}
              textAlign="left"
              fontSize="18px"
              fontWeight="normal"
              id={'reportName'}
              {...register('reportName', { required: true })}
              placeholder="Enter report name"
            />
            <Stack>
              <Text fontSize="xs">
                * To generate a report select a period and fill every{' '}
                <span style={{ color: '#d02534' }}>red</span> field
              </Text>
              <Stack direction="row" gap={2}>
                <Button
                  alignSelf="flex-end"
                  colorScheme="button.primary"
                  type="submit"
                  isLoading={isPdfLoading}
                  disabled={
                    isPdfLoading ||
                    isCsvLoading ||
                    !startDate ||
                    !endDate ||
                    selectedLocations.length === 0 ||
                    selectedDevices.length === 0 ||
                    !selectedReportType ||
                    !formIsValid
                  }
                  w="full"
                  h={12}
                  onClick={handleSubmit((data) => onSubmit(data, ReportType.Pdf))}
                >
                  <AiOutlineFilePdf
                    style={{
                      marginRight: '0.5em',
                      width: 26,
                      height: 26,
                    }}
                  />
                  Generate PDF Report
                </Button>

                <Button
                  alignSelf="flex-end"
                  colorScheme="button.primary"
                  type="submit"
                  isLoading={isCsvLoading}
                  disabled={
                    isPdfLoading ||
                    isCsvLoading ||
                    !startDate ||
                    !endDate ||
                    selectedLocations.length === 0 ||
                    selectedDevices.length === 0 ||
                    !selectedReportType ||
                    !formIsValid
                  }
                  w="full"
                  h={12}
                  onClick={handleSubmit((data) => onSubmit(data, ReportType.Csv))}
                >
                  <AiOutlineFileExcel
                    style={{
                      marginRight: '0.5em',
                      width: 26,
                      height: 26,
                    }}
                  />
                  Generate CSV Report
                </Button>
              </Stack>
            </Stack>
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}

export default GenerateReport
