import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getDeviceDataMock } from '../../../mockData/dashboard'
import { DeviceData } from './types'

// initialize an empty api service that we'll inject endpoints into later as needed
const pythonApi = createApi({
  reducerPath: 'pythonApi',
  keepUnusedDataFor: 300,
  tagTypes: ['List'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = window.localStorage.getItem('aiot_t')
      if (token) {
        headers.set('Authorization', `Token ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getDeviceData: builder.query<DeviceData.Response, DeviceData.Request>({
      query: ({ from, to, location, device }) => {
        const queryParams = new URLSearchParams()
        queryParams.append('from_date', from)
        queryParams.append('to_date', to)

        if (location) {
          queryParams.append('location', location)
        }

        if (device) {
          queryParams.append('device', device)
        }
        return {
          url: `/data/total/?${queryParams.toString()}`,
          method: 'GET',
        }
      },
      transformResponse: (response: any, _meta, arg) => {
        const mockResponse = getDeviceDataMock()

        const result = mockResponse ? mockResponse : response

        return result
      },
    }),
  }),
})

export default pythonApi
